.dragzone {
    border-style: dashed;
    border-width: 1px;
    border-color: grey;
    padding: 6px;
//     width: 200px;
//     height: 56px;
  }

  .dragzone-disabled {
    border-color: #bfbfbf;
    background-color: #f2f2f2;
  }

  .isDragActive {
        background-color: #D9D9D9;
  }
  
  .tooltip-top {
    margin-right: 5px;
  }
  .tooltipInit {
    position: relative;
    display: inline-block;
    
  }
  .tooltipInit .tooltipdropInit {
    visibility: hidden;
    width: 209px;
  background-color: #696969;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -8px;
  left: 195%;
  }
  .tooltip-top-rejected {
    z-index: 9999!important;
    display: block;
  }
  .uploadFileIcon {
    position: relative;
    display: inline-block;
  }
  
  .tooltipInit .tooltipdropInit::after {
    content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #696969 transparent transparent;
  }

  .tooltipInit:hover .tooltipdropInit{
    visibility: visible;
  }
  


  .isDragActive:hover .tooltiptext{visibility: visible;}
  .tooltipdrop {
    visibility: visible;
    width: 209px;
  background-color: #696969;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -8px;
  left: 150%;
  }
  .tooltipdrop:after {

    content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #696969 transparent transparent;
  
  }
  
  