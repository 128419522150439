
/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
/* DROPDOWN MENU CSS */
/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
/****************************/
.dropdown-menu-right{
  position: relative;
  left: -3px;
  background: #005a70;
  top: 2px;
  padding: 0;
  color:#505759;
  }

  .dropdown-menu-right span.dropdown-icon {
    /* margin: 7px 0 0 15px; */
    padding: 8px 0 0 8px;
    cursor: pointer; 
    display: inline-block; 
    -webkit-transition: width 2s;
    transition: width 2s;
  }
  
  .dropdown-menu-right span.dropdown-icon:after {
    content: url('data:image/svg+xml; utf8, <svg width="100%" height="16px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg"><path d="M 50 100 L 350 100 L 200 300 z" fill="whitesmoke" stroke="white" stroke-width="3" /></svg>');
    font-size: 16px;
    /* color: black; */
    /* background-color: black; */
  }
  
  .dropdown-menu-right span.dropdown-icon-open {
    /* margin: 7px 0 0 15px; */
    padding: 8px 0 0 8px;
    cursor: pointer; 
    display: inline-block; 
    -webkit-transition: width 2s;
    transition: width 2s;
  }
  
  .dropdown-menu-right span.dropdown-icon-open:after {
    content: url('data:image/svg+xml; utf8, <svg width="100%" height="16px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg"><path d="M 50,300 L 200,100 L 350,300 z" fill="whitesmoke" stroke="white" stroke-width="3" /></svg>');
    /* background-color: black; */
    font-size: 16px;
  }
  
  .dropdown-list-box
  {
      display: block;
      min-width:142px;
        max-width:220px;
      max-height: 140px;
      position: absolute;
      height: auto;    
      background: #ffffff;
      -webkit-border-radius: 1px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      border: #BCBCBC solid 1px;
      padding: 0;
      font-size:14px;    
      z-index: 10;
      right: -3px;
      top: 35px;
      box-shadow: 2px 2px 3px rgba(182, 184, 186, 0.75);
  }
  @-moz-document url-prefix(){
    .dropdown-list-box{
      right: -8px;
    }
    .dropdown-selected-option{
      left: 0;
    }
    .dropdown-menu-right span.dropdown-icon-open{
      margin: 0 10px 0 0;
    }
    .dropdown-menu-right span.dropdown-icon{
      margin: 0 10px 0 0;
    }
  }
  .dropdown-selected-option{
    color: white;
    margin: 0;
    padding: 0;
    position: relative;
    top: -4px;
    cursor: pointer;
  }
  