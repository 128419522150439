body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
*:focus{
  outline: none !important;
}
a:focus {
  outline: 0;
  outline: none;
}

.app {
  margin: 110px auto 0px auto;
  min-width: 550px;
  max-width: 1024px;
  height: 100%;
}

.test {
  z-index: -1;
  position: absolute;
  display: block;
  background-color: #f5f5f5;
  width: 100%;
  height: 100px;
}

.subNav {
  margin-top: 70px;
  margin-bottom: 30px;
}

.barStyleTabs {
  top: 85px;
  background-color: #005a70;
  height: 40px;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  padding-left: 20px;
  position: absolute;
  z-index: 99;
}
.fa-file-upload{
  color:#6a7070 !important;
  font-size: 70px;
}

.scrollTable{
  overflow-y: scroll;
  height: 200px;
}

.barStyleTabs .tabs__labels {
  position: absolute;
  bottom: 0;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.clearfix {
  display: block;
  clear: both;
}

.footer {
  width: 100%;
  position: relative;
  bottom: 0;
}

.breadcrumb-group {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.breadcrumb svg {
  color: #6a7070;
  padding: 0px 4px 0px 4px;
}

.breadcrumb a.active,
.breadcrumb a:active,
.breadcrumb a:focus,
.breadcrumb a:hover {
  color: #047a9c;
}
.pe-table-toolbar {
  margin-top: 28px;
}

.pe-table tbody tr td.no-hover:hover{
  cursor: default;
  background-color: white;
}

.pe-table tbody tr.no-hover:hover td{
  cursor: default;
  background-color: #F9F9F9;
}

.pe-table tbody tr td.center{
  text-align: center;
}

.pe-table tbody tr td.greytext{
  color: #C7C7C7;
}

/* .pe-table-toolbar .pe-btn {
  color: #6a7070;
  background-color: #e9e9e9;
  border-color: #c7c7c7;
  box-shadow: none;
  text-decoration: none;  
}                     */

.content {
  padding-top: 28px;
  margin-bottom:5rem;
}

hr {
  border-top: 1px solid #e9e9e9;
  color: #e9e9e9;
  margin-bottom: 20px;
  height: 0px;
}

.text-center {
  text-align: center;
}

.small-gap
{
  margin-bottom: 10px;
}
#disableSuccessBtn{display: none;}
.barStyleText{
  background-color: #005a70;
  margin: 7px 30px;
  color:white;
  
}

.barStyleDropdown{
  background-color: #005a70;
  margin: 0px 30px;
 color:white;
  
}

/* .proxyExtUserDropdown select option:checked:after {
  content: attr(title);
  background: #666;
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  border: none;} */

#proxyExtUserDropdown option {

  background-color: white;
  color:black;
  border: none;
}
  
.barStyleDropdown .pe-select-container,.barStyleDropdown .pe-select-container-focus{
border:none !important;
color : white;
}

.barStyleDropdown .pe-select-container option{
  background-color: white;
  color : black;
  }

#proxyExtUserDropdown{
  background-color: #047a9c;
  
  color:white;
}

/* TOOLTIP STARTS */
.uploadicontooltip {
  position: relative;
  display: inline-block;
  
}
.uploadicontooltip .tooltiptext {
  visibility: hidden;
  width: 210px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 11px;
  right: 107%;
}

.uploadicontooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}

.uploadicontooltip:hover .tooltiptext{
  visibility: visible;
}
.tooltip {
  position: relative;
  display: inline-block;
  
}

.groupIcon {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  height: 15px;
}

.dropDownContainer select option {
  color: #000;
  background-color: #fff;
  border: 0px;
}

.pe-select-container-focus select:focus {
 
  background-color:white;
  border: 1px solid black;
}

.tooltip .tooltiptext-upload-rejected {
  visibility: hidden;
  width: 299px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -40px;
  right: 137%;
}

.tooltip .tooltiptext-sample-recalled {
  visibility: hidden;
  width: 299px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -40px;
  right: 137%;
}
.tooltip .tooltiptext-rejectrReason {
  visibility: hidden;
  width: 299px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -22px;
  right: 137%;
}
.tooltip .tooltiptext-ziprejectrReason {
  visibility: hidden;
  width: 400px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -51px;
  right: 137%;
}

.tooltip-top-rejected {
  z-index: 9999!important;
  display: block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 299px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -48px;
  right: 137%;
}
.tooltip .tooltiptext-ziprejectrReason:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}
.tooltip .tooltiptext-rejectrReason:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
  z-index: 99999;
}


.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}
.tooltip .tooltiptext-upload-rejected::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}

.tooltip .tooltiptext-upload-rejected::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}

.tooltip .tooltiptext-sample-recalled::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}
.tooltip:hover .tooltiptext, .tooltip:hover  .tooltiptext-upload-rejected,.tooltip:hover .tooltiptext-rejectrReason, .tooltip:hover .tooltiptext-ziprejectrReason,.tooltip:hover  .tooltiptext-sample-recalled{
  visibility: visible;
}
.pe-icon--warning-sm-18{color:#DB0020}
.searchBox{    float: left;
  width: 60%;}
.searchButton{    float: left;
width: 34%;
padding-left: 3%;
padding-top: 3.5%;
}

.pe-template__static-medium{width:860px !important;}


/* TOOLTIP ENDS */

/* TABLE MENU CSS */
.table-menu div.vdots {
  margin: 10px 0 0 15px;
  cursor: pointer;
}

.table-menu div.vdots:after {
  content: '\2807';
  font-size: 20px;
  }

.bubble
{
	position: absolute;
  /* width: auto;*/
  min-width:140px;
	max-width:160px;
  height: auto;
  left: -28px;
  top: 32px;
	background: #ffffff;
	-webkit-border-radius: 1px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	border: #5f5f5f solid 1px;
	padding: 5px;
	/* font-family: tahoma; */
	font-size:14px;
  z-index: 10;
}

.bubble:after
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 32px;
}

.bubble:before
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #5F5F5F transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  left: 32px;

}

.table-menu{
  position: relative;
}


.link-holder{
	width: auto;
	height: auto;
}
.link-holder ul{
	padding: 0;
	margin: 0;
}
.link-holder ul li{
	list-style: none;
	border-bottom: 1px #CCC solid; /*#DEE7E7*/
  line-height: 18px;
  padding: 5px 0;
}
.link-holder ul li:last-child{
	border-bottom:none;
}
.link-holder a{
	color: #555; /* #535657; */
  padding: 0 7px;
  text-decoration: none;
  cursor: pointer;
}
.link-holder a:hover{
	/*color: white;
	background: #535657;*/
  display: block;
	/* color: #000; */     /* #0D1F22; */
}

.link-holder span.disabled-link{
  padding: 0 7px;
  color: #CCC;
}

/* TABLE MENU CSS ENDS */

/* tabs from old pearson elements sdk version */
/* Remove horizontal scrollbar from modal */
@media (min-width: 1280px){
  .modalBody { 
      overflow-x: hidden;
  }
}

/* Footer Component CSS */
.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding-bottom: 24px;
  background: #fff;
  padding-top: 9px;
  font-size: 14px;
}

.footer p {
  padding-bottom: 0 !important;
}

.hidden {
  display:none!important
}

.footer a {
  color:#047b9c
}

.footer a:focus,a:hover {
  color:#005a70;text-decoration:none
}

.footer ul {
  margin:0;
  list-style-type:none;
  display: inline-block;
  align-items: center;
  flex-flow: wrap;
  text-align: center;
  justify-content: center;
}

.footer li {
  margin-right:1em;
  padding-right:1em;
  border-right:1px solid #000;
  float: left;
}

@media (max-width:979px) {
.footer li {
  margin-bottom:12px
}

}

.footer .copyright {
  margin:0;
  display: inline-block;
    vertical-align: middle;
    padding-bottom: 9px;
}

@media (max-width:979px) {
.footer .copyright {
  text-align:center
}

}

.tabButtons{
  color: #6A7070;
  padding: 5px 0;
  text-decoration: none;
}

.activeTab.tabButtons {
  border-bottom-color: #19A5A3 !important;
  border-bottom: 2px solid transparent;
  color: #252525;
}

.tabButtons:hover {
  border-bottom: 2px solid transparent;
  border-bottom-color: #C7C7C7;
  color: #252525;

}
.uploadImage{
  margin-bottom: -10px;
  margin-top: 5px
}

.second-sample-text-color
{
  color: red;
}


/* when hover rows are READONLY */
.pe-table tbody tr.disabled-selected,
.pe-table tbody tr.disabled-selected:hover, 
.pe-table tbody tr.disabled-selected td{
  cursor: default;
  background-color:#daf0ed;
  border-top:1px solid #19a6a4;
  border-bottom:1px solid #19a6a4;
  border-collapse: collapse;
}

.pe-table tbody tr.disabled-not-selected td{
  cursor: default;
}
  .pe-table tbody tr.disabled-not-selected:hover{ 
  cursor: default;
  background-color: #336600;
}
/* when hover rows are READONLY--END */

.link-holder{
	width: auto;
  max-height: 140px;
  overflow: auto;
  scrollbar-width:thin;
  scrollbar-color:#a7a7a7 #e6e6e6;
}
.link-holder ul{
	padding: 0;
	margin: 0;
}
.link-holder ul li{
	list-style: none;
	border-bottom: 1px #CCC solid; /*#DEE7E7*/
  line-height: 18px;
  padding: 5px 0;
  margin: 0;
}
.link-holder ul li:last-child{
	border-bottom:none;
}
.link-holder a{
	color: #555; /* #535657; */
  padding: 3px 10px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin: 0;
}
.link-holder a:hover{
	color: #333;
	background: #E2E2E2; 
  /* display: block; */
	/* color: #000; */     /* #0D1F22; */
}

.link-holder span.disabled-link{
  padding: 0 7px;
  color: #CCC;
}
/***************************/
.link-holder::-webkit-scrollbar {
  width: .5em;
}
.link-holder::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.link-holder::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
}

img.breadCrumbImage, img.warningImage
{
  vertical-align: top;
}

.pe-icon--sortable-18 {
  background-image: url("sortable-18.svg") !important;
 }

 .pe-icon--sort-up-18 {
  background-image: url("sort-up-18.svg")  !important;
 }

 .pe-icon--sort-down-18 {
  background-image: url("sort-down-18.svg")  !important;
 }

 .pe-icon--dropdown-open-sm-18 {
  background-image: url("dropdown-open-sm-18.svg")  !important;
 }

 .pe-icon--check-sm-18 {
  background-image: url("check-sm-18.svg")  !important;
  vertical-align: top  !important;
  background-repeat: no-repeat;
 }

 .modalHeader .pe-icon--btn {
  background-image: url("remove-lg-18.svg")  !important;
  background-repeat: no-repeat;
  margin-top: 1px;
  cursor: pointer;
  margin: auto;
 }


 .pe-icon--dropdown-open-sm-24
 {
  background-image: url("dropdown-open-sm-24.svg")  !important;
  vertical-align: top  !important;
  background-repeat: no-repeat;
 }

 .isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.isDisabled:hover {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;

}


 .pe-label--bold {
  font-weight: bold;
 }

 .pe-bold {
  font-weight: bold;
 }

 .pe-row {
  display: flex;
  flex-direction: row;
 }

 .pe-col-6 {
  display: flex;
  flex: 1 1 0;
 }

 .pe-checkbox *{
  display: inline;
 }

 .pe-checkbox input{
  cursor: pointer;
 }
/******************/

/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
/* 3 DOTS MENU CSS ENDS */
/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
